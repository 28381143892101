import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import PlaceIcon from "@mui/icons-material/Place";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import CreateTwoToneIcon from "@mui/icons-material/CreateTwoTone";
import { fontWeight } from "@mui/joy/styles/styleFunctionSx";
import BankModal from "../Gold/BankModal";
import AddNewBank from "./AddNewBank";
import AccountModal from "../Gold/AccountModal";
import IpoAccountModal from "../Ipo/IpoAccountModal";
import IpoKycModal from "../Ipo/IpoKycModal";
import IpoBankModal from "../Ipo/IpoBankModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 900,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProfileWrapper = ({ user, accessToken }) => {
  const [goldProfile, setGoldProfile] = useState<any>(null);
  const [banks, setBanks] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedBank, setSelectedBank] = useState<any>(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [openAccount, setOpenAccount] = useState<boolean>(false);

  const [preIpoProfile, setPreIpoProfile] = useState<any>(null);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const [openIpoAccount, setOpenIpoAccount] = useState<boolean>(false);
  const [isKycOpen, setIsKycOpen] = useState<boolean>(false);
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    fetchAccountData();
    // fetchPreIpoProfile();
    if (isUpdated) fetchBankData();
  }, [isUpdated]);

  const fetchAccountData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/profile`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let data = response.data.data;
      setGoldProfile(data);
      // setIsUpdated(false);
      // console.log("User Response ->", response.data.data);
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  // const fetchPreIpoProfile = async () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_HOST}v1/preIpo/profile`, {
  //       headers: { Authorization: `Bearer ${accessToken}` },
  //     })
  //     .then(({ data }) => {
  //       console.log("data ->", data);
  //       setPreIpoProfile(data.data);
  //     })
  //     .catch(({ response }) => {
  //       console.log("Error from fetch PreIpo Profile ->", response);
  //     });
  // };

  const fetchBankData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/bank/all`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let data = response.data.data;
      setBanks(data);
      setIsUpdated(false);
      console.log("Banks Response ->", data);
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  const updateBank = async (row: any) => {
    setSelectedBank(row);
    setIsOpen(true);
  };

  return (
    <>
      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Mutual Fund
          </Typography>
        </Paper>
      </Grid>
      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Gold Investment
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {goldProfile ? (
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "text.secondary",
                        }}
                      >
                        Profile
                      </Typography>
                      <IconButton
                        aria-label="editor"
                        color="primary"
                        onClick={() => setOpenAccount(true)}
                      >
                        <CreateTwoToneIcon sx={{ fontSize: 28 }} />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        marginBottom: 2,
                        marginTop: 3,
                      }}
                    >
                      <PersonRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 30 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {`${goldProfile.user.name} (${goldProfile.user.unique_id})`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <PhoneRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {goldProfile.user.mobileNumber}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <EmailRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {goldProfile.user.email ? goldProfile.user.email : "-"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <DateRangeRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {goldProfile.user.dob
                          ? new Date(goldProfile.user.dob).toDateString()
                          : ""}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <CreditCardRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {goldProfile?.user?.pan?.panNumber ?? "-"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <PlaceIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {goldProfile?.address?.address ?? "-"}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="h6">
                        Create Account
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => setOpenAccount(true)}
                      >
                        Pending
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button variant="contained" onClick={() => setIsOpen(true)}>
                      Add
                    </Button>
                  </Box> */}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Account Name</StyledTableCell>
                      <StyledTableCell align="right">
                        Account Number
                      </StyledTableCell>
                      <StyledTableCell align="right">Bank Name</StyledTableCell>
                      <StyledTableCell align="right">IFSC Code</StyledTableCell>
                      <StyledTableCell align="right">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {banks?.length > 0 ? (
                      banks?.map((row: any) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell component="th" scope="row">
                            {row.accountName}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.accountNumber}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.bankName}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.ifscCode}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton
                              aria-label="editor"
                              onClick={() => updateBank(row)}
                            >
                              <CreateTwoToneIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <Typography component="th" scope="row"></Typography>
                        <Typography component="th" scope="row">
                          Bank are not found
                        </Typography>
                        <Typography component="th" scope="row"></Typography>
                        <Typography component="th" scope="row"></Typography>
                        <Typography component="th" scope="row"></Typography>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Pre IPO Shares
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={4}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      Create Account
                    </Typography>
                    {preIpoProfile ? (
                      <Button
                        variant="contained"
                        onClick={() => setOpenIpoAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      <>
                        <Typography style={{ fontWeight: 600 }} variant="h6">
                          Create Account
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => setOpenIpoAccount(true)}
                        >
                          Pending
                        </Button>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ marginTop: 5 }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      KYC
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => setIsKycOpen(true)}
                    >
                      Pending
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Card sx={{ alignSelf: "start" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      Bank Details
                    </Typography>

                    <Button
                      variant="contained"
                      onClick={() => setIsOpenBank(true)}
                    >
                      Pending
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      {isOpen && (
        <AddNewBank
          isOpenBank={isOpen}
          setIsOpenBank={setIsOpen}
          bank={selectedBank}
          accessToken={accessToken}
          setIsUpdated={setIsUpdated}
          setSelectedBank={setSelectedBank}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {openAccount && (
        <AccountModal
          openAccount={openAccount}
          setOpenAccount={setOpenAccount}
          accessToken={accessToken}
          userData={goldProfile?.user}
          userAddress={goldProfile?.address}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {/* {openIpoAccount && (
        <IpoAccountModal
          openAccount={openIpoAccount}
          setOpenAccount={setOpenIpoAccount}
          accessToken={accessToken}
          userData={preIpoProfile?.customer}
          // userAddress={goldProfile?.address}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isKycOpen && (
        <IpoKycModal
          isKycOpen={isKycOpen}
          setIsKycOpen={setIsKycOpen}
          //   userData={userData?.user}
          //   panData={
          //     userData?.user?.pan
          //       ? userData?.user?.pan?.panNumber
          //       : user.panNumber
          //   }
          accessToken={accessToken}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isOpenBank && (
        <IpoBankModal
          isOpenBank={isOpenBank}
          setIsOpenBank={setIsOpenBank}
          //   userData={userData?.user}
          //   bank={userBank}
          //   accessToken={accessToken}
          //   setSuccess={setSuccess}
          //   setIsUpdated={setIsUpdated}
          //   setError={setError}
          //   setMsg={setMsg}
        />
      )} */}
      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
};

export default ProfileWrapper;
