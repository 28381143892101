import React, { useEffect, useState } from "react";
import TabsButtons from "../TabsButtons";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PreIpoList from "./PreIpoList";
import axios from "axios";
import Loading from "../../Dashboard/Loading";

const PreIpoExplore = ({ accessToken }) => {
  const [value, setValue] = useState("1");
  const [ipoList, setIpoList] = useState([]);
  const [isLoading, setIsLOading] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    //   setSchemes([]);
    //   setSelectedScheme("");
  };
  useEffect(() => {
    getIopList();
  }, [value]);
  console.log("Ipo List value ->", value);

  const getIopList = () => {
    // setSelectedScheme(fundType);
    setIsLOading(true);
    let type =
      value === "1" ? "live" : value === "2" ? "upcoming" : "historical";
    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST +
          `v1/preIpo/products?category=${type}`,
        // {
        //   fund: fundType,
        // },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => {
        console.log("Response Ipo list ->", res.data);

        setIpoList(res.data.data.collection);
        setIsLOading(false);
        // setSortConfig({ key: "", direction: "asc" });
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  };
  return (
    <>
      <TabsButtons investment={"ipo"} page={"explore"} />
      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Live" value="1" />
            <Tab label="Upcoming" value="2" />
            <Tab label="Historical" value="3" />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={2}>
            {isLoading ? <Loading /> : <PreIpoList list={ipoList} />}
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container spacing={2}>
            {isLoading ? <Loading /> : <PreIpoList list={ipoList} />}
          </Grid>
        </TabPanel>
        <TabPanel value="3">
          <Grid container spacing={2}>
            {isLoading ? <Loading /> : <PreIpoList list={ipoList} />}
          </Grid>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default PreIpoExplore;
