import React, { useEffect, useState } from "react";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import TabsButtons from "../TabsButtons";
import InfoIcon from "@mui/icons-material/Info";
import { FormatNumber } from "../../../utils/formatNumber";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import AccountModal from "./IpoAccountModal";
// import KycModal from "./IpoKycModal";
// import BankModal from "./IpoBankModal";

const IpoInvestment = ({ user, accessToken }) => {
  const navigate = useNavigate();
  const [preIpoProfile, setPreIpoProfile] = useState<any>(null);

  useEffect(() => {
    fetchPreIpoProfile();
  }, []);

  const fetchPreIpoProfile = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/preIpo/profile`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(({ data }) => {
        console.log("data ->", data);
        setPreIpoProfile(data);
      })
      .catch(({ response }) => {
        console.log("Error from fetch PreIpo Profile ->", response);
      });
  };

  return (
    <>
      <TabsButtons investment={"ipo"} page={"home"} />

      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={8} md={6} lg={4}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: 600 }} variant="h6">
                    Pre IPO Shares
                  </Typography>
                  {preIpoProfile ? (
                    <Button
                      variant="contained"
                      color="success"
                      // onClick={() => setOpenAccount(true)}
                    >
                      Done
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // onClick={() => setOpenAccount(true)}
                      onClick={() => navigate("/dashboardAdmin/profile")}
                    >
                      Create
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default IpoInvestment;
