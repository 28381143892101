import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PreIpoList = ({ list }) => {
  const navigate = useNavigate();
  console.log("List Data->", list);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow sx={{ background: "#E8E9EB" }}>
            <TableCell>
              <Typography>ISIN</Typography>
            </TableCell>
            <TableCell
              align="left"
              //   onClick={() => handleSort("corpus_in_crs")}
              sx={{ cursor: "pointer" }}
            >
              <div
              // style={{
              //   display: "flex",
              //   alignItems: "l",
              //   gap: "4px",
              //   justifyContent: "center",
              // }}
              >
                <Typography>Name of Security</Typography>{" "}
                {/* <Typography> {getSortIcon("corpus_in_crs")}</Typography> */}
              </div>{" "}
            </TableCell>
            <TableCell
              align="left"
              //   onClick={() => handleSort("returns.one_year")}
              sx={{ cursor: "pointer" }}
            >
              <div
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   gap: "4px",
              //   justifyContent: "center",
              // }}
              >
                <Typography> Sector</Typography>{" "}
                {/* <Typography> {getSortIcon("returns.one_year")}</Typography> */}
              </div>{" "}
            </TableCell>
            <TableCell align="left">
              <Typography> Max Amt</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography> Max Units</Typography>
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map((row: any) => {
            return (
              <TableRow
                key={row.id || row.name}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    background: "#E8E9EB",
                  },
                }}
              >
                <TableCell
                  sx={{ cursor: "pointer", paddingY: "0px" }}
                  component="th"
                  scope="row"
                  width="auto"

                  //   onClick={() =>
                  //     navigate(`/dashboardAdmin/mutual-fund-explore/${row.id}`)
                  //   }
                >
                  {row.isin}
                </TableCell>
                <TableCell sx={{ paddingY: "0px" }} align="left" width="auto">
                  {row.company.display_name}
                </TableCell>
                <TableCell sx={{ paddingY: "0px" }} align="left" width="auto">
                  {row.company.sector}
                </TableCell>
                <TableCell sx={{ paddingY: "0px" }} align="left" width="auto">
                  {row.max_amt}
                </TableCell>
                <TableCell sx={{ paddingY: "0px" }} align="left" width="auto">
                  {row.max_units}
                </TableCell>
                <TableCell sx={{ paddingY: "5px" }} align="left" width="auto">
                  <div
                    style={{
                      padding: "10px",
                      width: "100px",
                      borderRadius: "5px",
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#ffa500",
                      cursor: "pointer",
                    }}
                    onClick={(e) =>
                      navigate(`/dashboardAdmin/apply-ipo`, {
                        state: { value: row },
                      })
                    }
                  >
                    Buy Now
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PreIpoList;
