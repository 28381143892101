import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { debit, equity, hybrid, other, solution } from "../../../Data/data";
import SchemeList from "./SchemeList";
import { BorderBottom } from "@mui/icons-material";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import * as debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import TabsButtons from "../TabsButtons";

const Explore = ({ accessToken }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [schemes, setSchemes] = useState<any>([]);
  const [selectedScheme, setSelectedScheme] = useState<string>("");
  const [queryList, setQueryList] = useState<any>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSchemes([]);
    setSelectedScheme("");
  };

  const getSchemes = (fundType: string) => {
    setSelectedScheme(fundType);

    axios
      .post(
        process.env.REACT_APP_BACKEND_HOST + "v1/funds/schemes",
        {
          fund: fundType,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => {
        setSchemes(res.data.schemes);
        setSortConfig({ key: "", direction: "asc" });
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  };

  const handleSearch = debounce(async (value: string) => {
    if (value.length >= 3) {
      setShowList(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/funds/search?scheme=${value}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((res) => {
          console.log("List Response ->", res.data.scheme);
          setQueryList(res.data.scheme);
        })
        .catch((err) => {
          console.log("err ->", err);
        });
    } else {
      setQueryList([]);
      setShowList(false);
    }
  }, 500);

  const handleChangeQuery = async (e: any) => {
    let { value } = e.target;
    handleSearch(value);
    setShowList(true);
    console.log("Query value ->", value);
  };

  return (
    <>
      {/* <TabsButtons investment={"mutual_fund"} page={"explore"} /> */}
      <Grid container sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
            width: "100%",
            position: "relative",
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              padding: "10px",
            }}
            variant="h6"
            // sx={{ mb: 2 }}
          >
            Explore All Fund Categories
          </Typography>
          <TextField
            sx={{ marginRight: 3, width: "33%" }}
            size="small"
            variant="outlined"
            onChange={handleChangeQuery}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  // style={{ display: showClearIcon }}
                  // onClick={handleClick}
                >
                  {/* <ClearIcon /> */}
                </InputAdornment>
              ),
            }}
          />
          {showList && (
            <Box
              sx={{
                position: "absolute",
                right: 10,
                top: 40,
                zIndex: 1,
                width: "35%",
              }}
            >
              <Paper elevation={24} sx={{ borderRadius: 2 }}>
                <List
                  sx={{ width: "100%", bgcolor: "background.paper" }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  {queryList.map((item: any) => {
                    return (
                      <ListItemButton
                        key={item.id}
                        onClick={() =>
                          navigate(
                            `/dashboardAdmin/mutual-fund-explore/${item.id}`
                          )
                        }
                      >
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Paper>
            </Box>
          )}
        </Box>
      </Grid>

      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Equity" value="1" />
            <Tab label="Debt" value="2" />
            <Tab label="Hybrid" value="3" />
            <Tab label="Other Funds" value="4" />
            <Tab label="Solution Oriented" value="5" />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={2}>
            {equity.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={2} key={item.id}>
                  <Paper
                    style={{
                      background: `${
                        selectedScheme === item.name ? "#bbc4c2" : "#E8E9EB"
                      }`,
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => getSchemes(item.name)}
                  >
                    <Avatar style={{ backgroundColor: "#7f97c7" }}>
                      {item.name.charAt(0)}
                    </Avatar>
                    <Typography> {item.name}</Typography>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container spacing={2}>
            {debit.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={2} key={item.id}>
                  <Paper
                    style={{
                      background: `${
                        selectedScheme === item.name ? "#bbc4c2" : "#E8E9EB"
                      }`,
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => getSchemes(item.name)}
                  >
                    <Avatar style={{ backgroundColor: "#7f97c7" }}>
                      {item.name.charAt(0)}
                    </Avatar>
                    <Typography> {item.name}</Typography>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value="3">
          <Grid container spacing={2}>
            {hybrid.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={2} key={item.id}>
                  <Paper
                    style={{
                      background: `${
                        selectedScheme === item.name ? "#bbc4c2" : "#E8E9EB"
                      }`,
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      height: "70px",
                      cursor: "pointer",
                    }}
                    onClick={() => getSchemes(item.name)}
                  >
                    <Avatar style={{ backgroundColor: "#7f97c7" }}>
                      {item.name.charAt(0)}
                    </Avatar>
                    <Typography> {item.name}</Typography>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value="4">
          <Grid container spacing={2}>
            {other.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={2} key={item.id}>
                  <Paper
                    style={{
                      background: `${
                        selectedScheme === item.name ? "#bbc4c2" : "#E8E9EB"
                      }`,
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      height: "70px",
                      cursor: "pointer",
                    }}
                    onClick={() => getSchemes(item.name)}
                  >
                    <Avatar style={{ backgroundColor: "#7f97c7" }}>
                      {item.name.charAt(0)}
                    </Avatar>
                    <Typography> {item.name}</Typography>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value="5">
          <Grid container spacing={2}>
            {solution.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={2} key={item.id}>
                  <Paper
                    style={{
                      background: `${
                        selectedScheme === item.name ? "#bbc4c2" : "#E8E9EB"
                      }`,
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => getSchemes(item.name)}
                  >
                    <Avatar style={{ backgroundColor: "#7f97c7" }}>
                      {item.name.charAt(0)}
                    </Avatar>
                    <Typography> {item.name}</Typography>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
      </TabContext>
      {schemes?.length > 0 && (
        <Box sx={{ borderTop: 1, borderColor: "divider" }}>
          <Typography variant="h5" sx={{ padding: 4 }}>
            List
          </Typography>
          <SchemeList
            schemes={schemes}
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
          />
        </Box>
      )}
    </>
  );
};

export default Explore;
