import React from "react";
import TabsButtons from "../TabsButtons";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const ApplyIpo = ({ user, accessToken }) => {
  const { state }: any = useLocation();
  //   const { value } = state;
  const value = state?.value || null;
  //   console.log("Value ->", value);

  return (
    <>
      <TabsButtons investment={"ipo"} page={"invest"} />
      <Typography>Apply IPO</Typography>
    </>
  );
};

export default ApplyIpo;
