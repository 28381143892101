import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import { FormatNumber } from "../../utils/formatNumber";
import { useAppContext } from "../../Store/AppContext";
import HoverData from "../Main/HoverData";
import TabsButtons from "../Admin/TabsButtons";

export default function View(props: any) {
  const [storeState, dispatch] = useAppContext();
  const [tranx, setTranx] = useState<any>([]);
  const [Data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [msg, setMsg] = useState("");
  const [isFail, setIsFail] = useState(false);
  const [FolioData, setFolioData] = useState<any>([]);
  const [yesterdayEarnedBreakup, setYesterdayEarnedBreakup] = useState([]);
  const [totalSum, setTotalSum] = useState({
    totalInvested: 0,
    totalCurrentValue: 0,
    totalEarnedYesterDay: 0,
    totalEarnedTillDate: 0,
  });

  const navigate = useNavigate();
  //const [invtType, setInvtType] = useState([{ code: 1, name: "Individual" }, { code: 2, name: "Proprietorship" }, { code: 3, name: "Partnership" }, { code: 4, name: "Company" }])

  const { state }: any = useLocation();

  const dateConverter = (str) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(str);
    var mnth = date.getMonth();
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${day}-${month[mnth]}-${year}`;
  };

  const getTransactionReports = async () => {
    if (state.folio !== undefined) {
      setLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/super/transactionreport`,
          {
            params: {
              pan: state.folio.pan,
            },
            headers: {
              Authorization: `Bearer ${props.accessToken}`,
            },
          }
        )
        .then(async (res) => {
          const { data } = res;
          if (!data.succ) {
            setIsFail(true);
            setMsg(data.message);
            setData({});
            setTotalSum({
              totalCurrentValue: 0,
              totalEarnedTillDate: 0,
              totalEarnedYesterDay: 0,
              totalInvested: 0,
            });

            setEmpty(false);
            setLoading(false);
            return;
          }
          // <<<<<<< HEAD
          //         const { Data, TotalEarn, FolioData,yesterdayEarnedBreakup } = data;
          //         setFolioData(FolioData);
          //         setYesterdayEarnedBreakup(yesterdayEarnedBreakup);
          //         if (Object.keys(Data).length > 0) {
          //           setData(Data);
          // =======
          const { Data, TotalEarn, FolioData } = data;
          setFolioData(FolioData);
          if (Object.keys(Data).length > 0) {
            setData(Data);

            // >>>>>>> fd_ui_changes
            setTotalSum((totalSum) => ({
              ...totalSum,
              ...TotalEarn,
            }));
          } else {
            setData({});
            setTotalSum({
              totalCurrentValue: 0,
              totalEarnedTillDate: 0,
              totalEarnedYesterDay: 0,
              totalInvested: 0,
            });
            setEmpty(false);
            setLoading(false);
            return;
          }
        })
        .catch((error) => {
          setData({});
          setTotalSum({
            totalCurrentValue: 0,
            totalEarnedTillDate: 0,
            totalEarnedYesterDay: 0,
            totalInvested: 0,
          });
          setFolioData({});
          setEmpty(false);
          setMsg(error);
          setLoading(false);
          return;
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactionReports();
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <TabsButtons investment="mutual_fund" page="view" />

      <Grid container spacing={2} m={2} mt={4}>
        <Snackbar
          open={isFail}
          autoHideDuration={5000}
          onClose={() => setIsFail(false)}
        >
          <Alert severity="error">{msg}</Alert>
        </Snackbar>
        {!state?.folio?.Folio && (
          <Grid
            sx={{
              display: "flex",
              position: "fixed",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "-20px",
              zIndex: "10",
              background: "#F0E68D",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body1">
              Thank you for uploading your details.We will compelete your KYC
              and create your account using these
            </Typography>
            <Typography variant="body1">
              details. Meanwhile for any queries, please reach out at 9818373388
              or shikher@klarfin.com
            </Typography>
          </Grid>
        )}
        <Paper elevation={1} sx={{ p: 4, pl: 6, pr: 6 }}>
          <Typography style={{ fontWeight: 600 }} variant="h6" sx={{ mb: 2 }}>
            Overview
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minWidth: "69vw",
            }}
          >
            <Grid sx={{ display: "flex", flexDirection: "row" }}>
              <Paper
                elevation={2}
                sx={{ padding: "16px", textAlign: "center", width: "50%" }}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Total Invested
                </Typography>
                <Typography variant="h4">
                  {FormatNumber(totalSum.totalInvested)}
                </Typography>
              </Paper>
              <Paper
                elevation={2}
                sx={{ padding: "16px", textAlign: "center", width: "50%" }}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Current Value
                </Typography>
                <Typography variant="h4">
                  {FormatNumber(totalSum.totalCurrentValue)}
                </Typography>
              </Paper>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "row" }}>
              <Paper
                elevation={2}
                sx={{
                  padding: "16px",
                  textAlign: "center",
                  width: "50%",
                  position: "relative",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Money Earned Yesterday
                </Typography>
                <Typography variant="h4">
                  {FormatNumber(totalSum.totalEarnedYesterDay)}
                </Typography>
                {isHovered && <HoverData data={yesterdayEarnedBreakup} />}
              </Paper>
              <Paper
                elevation={2}
                sx={{ padding: "16px", textAlign: "center", width: "50%" }}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Returns through Klarfin till Date
                </Typography>
                <Typography variant="h4">
                  {FormatNumber(totalSum.totalEarnedTillDate)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={2} sx={{ p: 4, pl: 6, pr: 6 }}>
          <Typography style={{ fontWeight: 600 }} variant="h6" sx={{ mb: 2 }}>
            Scheme Wise Report
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              minWidth: "69vw",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Scheme
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Amount Invested
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Current / Market Value
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Folios
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center" alignItems="center">
            <Typography style={{ fontWeight: 600, color: "grey" }} variant="body1" >
              Actions
            </Typography>
          </Grid> */}
          </div>
          <Divider sx={{ mb: 2, mt: 3 }} />
          {!isEmpty &&
            Object.entries(Data)?.map((each, idx) => {
              const key = each[0];
              const value: any = each[1];
              const folios = FolioData[value.Scheme_Code];
              return (
                <>
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      minWidth: "69vw",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        {key}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6">
                          {FormatNumber(value.purchaseAmount)}
                        </Typography>
                        <Typography
                          variant="caption"
                          color={value.gain < 0 ? "red" : "#32CD32"}
                          sx={{ textAlign: "end" }}
                        >
                          {value.gain < 0
                            ? FormatNumber(value?.gain)
                            : `+ ${FormatNumber(value?.gain)}`}
                        </Typography>
                        <Typography
                          variant="caption"
                          color={value.xirr_percentage < 0 ? "red" : "#32CD32"}
                          sx={{ textAlign: "end" }}
                        >
                          {value.xirr_percentage < 0
                            ? `XIRR : ${FormatNumber(value?.xirr_percentage)}%`
                            : `XIRR : +${FormatNumber(
                                value?.xirr_percentage
                              )}%`}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">
                        {FormatNumber(value.currentValue)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      {Object.entries(folios).map(([name, val, indx]: any) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              border: "1px solid #2f85d7",
                              p: 1,
                              borderRadius: "5px",
                              height: "120px",
                              width: "230px",
                              textOverflow: "scroll",
                              m: 0.25,
                            }}
                          >
                            <Typography sx={{ fontSize: "14px" }}>
                              Folio Number : {name}
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              Units : {val.currentUnits}
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              Current Nav : {val.curr_nav}
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              Nav Date : {val.nav_date}
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              Amount : {FormatNumber(val.Amount)}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Grid>
                    {/* <Grid container xs={12} sm={6} md={4} display="flex" justifyContent="center" alignItems="center">
                <Grid item sx={{ display: "flex", flexDirection: "row", gap: "10px" }} >
                  <div style={{ padding: "10px", width: "100px", borderRadius: "5px", textAlign: "center", color: "white", backgroundColor: "#ffa500", cursor: "pointer" }} onClick={(e) => navigate(`/dashboardAdmin/nse/order/${storeState.ACTIVEINVETOR.folio.Folio}`, { state: { value } })}  > Buy More</div>
                  <div style={{ padding: "10px", width: "100px", borderRadius: "5px", textAlign: "center", color: "white", backgroundColor: "#318cd6", cursor: "pointer" }} onClick={(e) => navigate(`/dashboardAdmin/nse/redeem/${storeState.ACTIVEINVETOR.folio.Folio}`, { state: { value } })} > Redeem </div>
                </Grid>
              </Grid> */}
                  </div>
                  {idx !== tranx.length - 1 && (
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  )}
                </>
              );
            })}
        </Paper>
      </Grid>
    </>
  );
}
