import React from "react";
import TabsButtons from "../TabsButtons";

const InvestByPreIpo = ({ user, accessToken }) => {
  return (
    <>
      <TabsButtons investment={"ipo"} page={"invest"} />
    </>
  );
};

export default InvestByPreIpo;
