import { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "../Layout/adminLayout";
import Form from "./Form";
import Users from "./Users";
import HorizontalLinearStepper from "./CreateInvesters/stepper";
import CreateOrderNippon from "./CreateOrder/CreateOrderNippon";
import CreateOrderNSE from "./CreateOrder/CreateOrderNSE";
import Orders from "./orders";
import RedeemStepper from "./RedeemOrder/RedeemStepper";
import Redeem from "./redeems";
import CashFlows from "./CashFlows";
import Insights from "./Insights";
import Receivables from "./Receivables";
import Bills from "./Bills";
import Settings from "./Settings";
import Upload_stepper from "./CreateInvesters/Upload_doc/upload_stepper";
// import CreditManagement from "./CreditManagement";
import Investment from "../Admin/Investment";
import NipponBank from "./NipponBank";
import NEFTAccountDetails from "./CreateInvesters/Nippon/NEFTAccountDetails";
import TransactionDatewiseNSE from "./TransactionDatewiseNSE";
import RTGSPayment from "./CreateOrder/RTGSPayment";
import ContactUs from "./ContactUs";
import TransactionPending from "./TransactionPending";
import CreateMultipleOrdersNSE from "./CreateOrder/CreateMultpileOrdersNSE";
import RedeemCreate from "./RedeemOrder/RedeemCreate";
import SignupStepper from "../UserEntry/NSE/SignupStepper";
import Sigin from "../UserEntry/NSE/Sigin";
import AddInvestorType from "./CreateInvesters/AddInvestorType";
import IINstatus from "./CheckIINactivation/IINstatus";
import UploadCancelCheque from "./CheckIINactivation/UploadCancelCheque";
import Explore from "./Explore/Explore";
import SchemeDetails from "./Explore/SchemeDetails";
import CreateFDOrder from "../Admin/CreateFdOrder/CreateFDOrder";
import IssuerDetail from "../Admin/CreateFdOrder/IssuerDetail";
import GetDetails from "../Admin/GetFdDetails/getDetails";
import PurchaseDetails from "./CreateFdOrder/PurchaseDetails";
import CreatePayment from "./CreateFdPayment/CreatePayment";
import InvestTabs from "./InvestTabs";
import FdTransactions from "./FdTransactions";
import HomeTabs from "./HomeTabs";
import FdInvestment from "./FdInvesment";
import GoldInvestment from "./Gold/GoldInvetment";
import ProfileWrapper from "./Profile/ProfileWrapper";
import BuyGoldSilver from "./Gold/BuyGoldSilver";
import GoldTransaction from "./Gold/GoldTransactions";
import GoldRedeem from "./Gold/GoldRedeem";
import IpoInvestment from "./Ipo/IpoInvestment";
import InvestByPreIpo from "./Ipo/InvestByPreIpo";
import PreIpoExplore from "./Explore/PreIpoExplore";
import ApplyIpo from "./Ipo/ApplyIpo";

const DashboardAdmin = (props) => {
  const { user, accessToken } = props;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/dashboardAdmin/users"
          element={
            <AdminLayout user={{}}>
              <Users accessToken={accessToken} setIsLoggedIn={setIsLoggedIn} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/investment"
          element={
            <AdminLayout user={{}}>
              <Investment accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/order/:folio"
          element={
            <AdminLayout user={{}}>
              <CreateOrderNippon accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/nse/order/:folio"
          element={
            <AdminLayout user={{}}>
              <CreateOrderNSE accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/nse/multi/order/:folio"
          element={
            <AdminLayout user={{}}>
              <CreateMultipleOrdersNSE accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/contact"
          element={
            <AdminLayout user={{}}>
              <ContactUs />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/upload/cancelcheque"
          element={
            <AdminLayout user={{}}>
              <UploadCancelCheque accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/nippon-bank/:folio_id"
          element={
            <AdminLayout user={{}}>
              <NipponBank accessToken={accessToken} />
            </AdminLayout>
          }
        />
        {/* <Route path="/dashboardAdmin/add-investment" element={<AdminLayout
          user={{}} >
          <HorizontalLinearStepper  accessToken={accessToken} />
        </AdminLayout>} /> */}
        <Route
          path="/dashboardAdmin/redeem/:folio_id"
          element={
            <AdminLayout user={{}}>
              <RedeemStepper accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/nse/redeem/:folio_id"
          element={
            <AdminLayout user={{}}>
              <RedeemCreate accessToken={accessToken} />
            </AdminLayout>
          }
        />
        {/* <Route path="/dashboardAdmin/add-investment/:id" element={<AdminLayout
          user={{}} >
          <Form accessToken={accessToken} />
        </AdminLayout>} /> */}
        <Route
          path="/dashboardAdmin/investment/details/:folio_id"
          element={
            <AdminLayout user={{}}>
              <Orders accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/investment/nse/details/:folio_id"
          element={
            <AdminLayout user={{}}>
              <TransactionDatewiseNSE accessToken={accessToken} />
            </AdminLayout>
          }
        />

        <Route
          path="/dashboardAdmin/investment/redeem/:folio_id"
          element={
            <AdminLayout user={{}}>
              <Redeem accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/add-investment/accountdetails"
          element={
            <AdminLayout user={{}}>
              <NEFTAccountDetails accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/sigup"
          element={
            <AdminLayout user={{}}>
              <SignupStepper />
            </AdminLayout>
          }
        />
        {/* <Route path="/dashboardAdmin/sigin" element={<AdminLayout
          user={{}} >
          <Sigin accessToken={accessToken} />
        </AdminLayout>} />  */}

        <Route
          path="/dashboardAdmin/investing"
          element={
            <AdminLayout user={user}>
              <Investment user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/mutual-fund-explore"
          element={
            <AdminLayout user={user}>
              <Explore accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/pre-ipo-explore"
          element={
            <AdminLayout user={user}>
              <PreIpoExplore accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/mutual-fund-explore/:scheme_id"
          element={
            <AdminLayout user={{}}>
              <SchemeDetails accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/check/kyc"
          element={
            <AdminLayout user={user}>
              <IINstatus user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/cashflow"
          element={
            <AdminLayout user={user}>
              <CashFlows accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/insights"
          element={
            <AdminLayout user={user}>
              <Insights accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/settings"
          element={
            <AdminLayout user={user}>
              <Settings
                role={user.role}
                email={user.email}
                accessToken={accessToken}
              />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/receivables"
          element={
            <AdminLayout user={user}>
              <Receivables name={user.name} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/bills-to-pay"
          element={
            <AdminLayout user={user}>
              <Bills />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/upload-doc"
          element={
            <AdminLayout user={user}>
              <AddInvestorType user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="*"
          element={<Navigate to="/dashboardAdmin/investing" replace />}
        />

        <Route
          path="/dashboardAdmin/issuer/:id"
          element={
            <AdminLayout user={user}>
              <IssuerDetail accessToken={accessToken} />
            </AdminLayout>
          }
        />

        <Route
          path="/dashboardAdmin/issuer/:id/purchase/:schemeId"
          element={
            <AdminLayout user={user}>
              <PurchaseDetails />
            </AdminLayout>
          }
        />

        <Route
          path="/dashboardAdmin/createfdpayment/:applicationId"
          element={
            <AdminLayout user={user}>
              <CreatePayment accessToken={accessToken} />
            </AdminLayout>
          }
        />

        <Route
          path="/dashboardAdmin/investtabs"
          element={
            <AdminLayout user={{}}>
              <InvestTabs />
            </AdminLayout>
          }
        />

        <Route
          path="/dashboardAdmin/fd-transactions"
          element={
            <AdminLayout user={user}>
              <FdTransactions accessToken={accessToken} />
            </AdminLayout>
          }
        />

        <Route
          path="/dashboardAdmin/gold-transactions"
          element={
            <AdminLayout user={user}>
              <GoldTransaction accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/goldRedeem"
          element={
            <AdminLayout user={user}>
              <GoldRedeem accessToken={accessToken} />
            </AdminLayout>
          }
        />

        {/* <Route
          path="/dashboardAdmin/hometabs"
          element={
            <AdminLayout user={user}>
              <HomeTabs />
            </AdminLayout>
          }
        /> */}

        <Route
          path="/dashboardAdmin/fdInvestment"
          element={
            <AdminLayout user={user}>
              <FdInvestment />
            </AdminLayout>
          }
        />

        {/* Gold Routes */}

        <Route
          path="/dashboardAdmin/goldInvestment"
          element={
            <AdminLayout user={user}>
              <GoldInvestment user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/profile"
          element={
            <AdminLayout user={user}>
              <ProfileWrapper user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/goldInvesting"
          element={
            <AdminLayout user={user}>
              <BuyGoldSilver user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/pre-ipo"
          element={
            <AdminLayout user={user}>
              <IpoInvestment user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/pre-ipo-investing"
          element={
            <AdminLayout user={user}>
              <InvestByPreIpo user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
        <Route
          path="/dashboardAdmin/apply-ipo"
          element={
            <AdminLayout user={user}>
              <ApplyIpo user={user} accessToken={accessToken} />
            </AdminLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default DashboardAdmin;
