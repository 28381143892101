import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { FormatNumber } from "../../../utils/formatNumber";
import { useAppContext } from "../../../Store/AppContext";
import TabsButtons from "../../Admin/TabsButtons";
import InfoIcon from "@mui/icons-material/Info";
import SuperAdminGoldAccountModal from "./SuperAdminGoldMemberAccountModel";
import SuperAdminGoldMemberKycModal from "./SuperAdminGoldMemberKyc";
import SuperAdminGoldMemberBankModal from "./SuperAdminGoldMemberBank";

export default function GoldMemberInvestment({ accessToken }) {
  const { state }: any = useLocation();
  const { id } = useParams();

  const [userData, setUserData] = useState<any>(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [openAccount, setOpenAccount] = useState<boolean>(false);
  const [isKycOpen, setIsKycOpen] = useState<boolean>(false);
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);
  const [storeState, dispatch] = useAppContext();
  const [Data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list);

  const fetchAccountData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/gold-member/profile?id=${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      const data = response.data.data;

      setUserData(data);
      setIsUpdated(false);
      // console.log("User Response ->", response.data.data);
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  // console.log("user ->", user);

  useEffect(() => {
    if (isUpdated) fetchAccountData();
  }, [isUpdated]);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const getTransactionReports = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/gold-member/transactions?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(async (res) => {
        const { data } = res;

        if (!data.succ) {
          setError(true);
          setMsg(data.message);
          setData({});
          setEmpty(false);
          return;
        }

        setData(data.data);
      })
      .catch((error) => {
        setData({});
        setEmpty(false);
        setMsg(error);
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    getTransactionReports();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <TabsButtons investment={"gold"} page={"view"} />

      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Gold Investment
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={4}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      Create Account
                    </Typography>
                    {userData ? (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setOpenAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => setOpenAccount(true)}
                      >
                        Pending
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ marginTop: 5 }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      KYC
                    </Typography>
                    {userData?.user?.kyc_status === 0 ? (
                      <Button
                        variant="contained"
                        onClick={() => setIsKycOpen(true)}
                      >
                        Pending
                      </Button>
                    ) : userData?.user?.kyc_status === 1 ? (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setOpenAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4}>
              <Card sx={{ alignSelf: "start" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      Bank Details
                    </Typography>

                    {userData?.bank ? (
                      <Button
                        variant="contained"
                        color="success"
                        // onClick={() => setOpenAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => setIsOpenBank(true)}
                      >
                        Pending
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, pl: 6, pr: 6 }}>
        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            overflow: { xs: "scroll", sm: "hide" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              minWidth: { xs: "50rem", sm: "69vw" },
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Gold / Silver
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Units
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Amount Invested (Excluding Tax.)
              </Typography>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Actions
              </Typography>
            </Grid> */}
          </Box>
          <Divider
            sx={{ mb: 2, mt: 3, minWidth: { xs: "50rem", sm: "69vw" } }}
          />
          {!isEmpty &&
            Object.entries(Data)?.map((each, idx) => {
              const key = each[0];
              const value: any = each[1];

              const quantity = value.quantity.toFixed(4);

              return (
                <>
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      minWidth: { xs: "50rem", sm: "69vw" },
                      overflowX: { xs: "scroll", sm: "hidden" },
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        display="flex"
                        alignItems="center"
                        gap={0.5}
                      >
                        {quantity}
                        <Tooltip
                          title={
                            <Box sx={{ fontSize: "1rem", textAlign: "center" }}>
                              <div>
                                Purchase : {value.purchaseQuantity.toFixed(4)}
                              </div>
                              <div>Gift : {value.giftQuantity.toFixed(4)}</div>
                            </Box>
                          }
                          arrow
                          placement="top" // Ensures tooltip appears above the icon
                        >
                          <InfoIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6">
                          {FormatNumber(value.investedAmount)}
                        </Typography>
                      </div>
                    </Grid>
                  </Box>
                  <Divider
                    sx={{
                      mt: 2,
                      mb: 2,
                      minWidth: { xs: "50rem", sm: "69vw" },
                    }}
                  />
                </>
              );
            })}
        </Box>
      </Paper>
      {openAccount && (
        <SuperAdminGoldAccountModal
          openAccount={openAccount}
          setOpenAccount={setOpenAccount}
          accessToken={accessToken}
          // userData={userData?.user ? userData?.user : user}
          userData={userData?.user ? userData?.user : state.row}
          userAddress={userData?.address}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isKycOpen && (
        <SuperAdminGoldMemberKycModal
          isKycOpen={isKycOpen}
          setIsKycOpen={setIsKycOpen}
          userData={userData?.user}
          panData={
            userData?.user?.pan?.panNumber
            // userData?.user?.pan
            //   ? userData?.user?.pan?.panNumber
            //   : user.panNumber
          }
          accessToken={accessToken}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isOpenBank && (
        <SuperAdminGoldMemberBankModal
          isOpenBank={isOpenBank}
          setIsOpenBank={setIsOpenBank}
          userData={userData?.user}
          bank={userBank}
          accessToken={accessToken}
          setSuccess={setSuccess}
          setIsUpdated={setIsUpdated}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {/* <PaymentPage accessToken={accessToken} /> */}

      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
}
